import React from "react";
import PropTypes from "prop-types";

import { Image, SlickSlider } from '../index';

import { validateImageCT, validateArray } from '../utilities/functions';
import styles from './index.module.scss';

const CustomersSlider = (props) => {

	const sliderSettings = {
		dots: true,
		pauseOnDotsHover: true,
		arrows: false,
		infinite: true,
		swipe: true,
		autoplay: true,
		autoplaySpeed: 3500,
		slidesToShow: 5,
		slidesToScroll: 5,
		// lazyLoad: true,
		accessibility: true,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					autoplay: true,
				}
			}
		]
	};

	const {
		data,
		className = '',
		settings = sliderSettings,
		hasHover,
		stacked = false,
		sliderSpeed = 3.5
	} = props;

	if (!validateArray(data))
		return null;

	settings.infinite = data.length > 5;
	if (sliderSpeed <= 9 && sliderSpeed >= 1) {
		settings.autoplaySpeed = sliderSpeed * 1000;
	}


	return (
		<>
			{
				(
					stacked &&
					<div className={styles.customers_stacked}>
						{
							data.map((customer, index) =>
								validateImageCT(customer) &&
								<div className={styles.customers_Image} key={index}>
									<Image
										data={customer}
										resizeWidth={200}
										format={false}
										style={(customer.max_height && { maxHeight: customer.max_height + 'px' })}
									/>
								</div>
							)
						}
					</div>

				)
				||
				<SlickSlider hasHover={hasHover} settings={settings} className={className + ' customers-slider ' + styles.CustomersSlider} >
					{
						data.map((customer, index) =>
							validateImageCT(customer) &&
							<div key={index} className='customer-logo'>
								<Image
									data={customer}
									resizeWidth={200}
									format={false}
									style={(customer.max_height && { maxHeight: customer.max_height + 'px' })} />
							</div>
						)
					}
				</SlickSlider>
			}
		</>
	);
};

CustomersSlider.propTypes = {
	data: PropTypes.array,
	className: PropTypes.string,
	settings: PropTypes.object,
	stacked: PropTypes.bool,
	hasHover: PropTypes.bool,
	sliderSpeed: PropTypes.number
};

export default CustomersSlider;
