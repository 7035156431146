import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Image } from '../index';
import { validateSingleLink, validateArray, validateImage } from '../utilities/functions';
import { BaseLink } from '../index';
import PropTypes from "prop-types";

import './footer.scss';

const Footer = ({hideFooterNextSection}) => (
	<StaticQuery
		query={query}
		render={data => {

			if (!data && !data.contentstackFooter) return null;

			const { next_project, social_links, navigation, copyright, soc_section } = data.contentstackFooter;

			return (
				<footer className='page-footer pinstripes-bg'>
					<div className='container'>
						{
							(next_project && !hideFooterNextSection) &&
							<div className='footer-top text-center'>
								{
									next_project.heading &&
									<h2 className='section-title'>{next_project.heading}</h2>
								}
								{
									validateSingleLink(next_project.cta) &&
									<BaseLink
										data={
											{
												display_title: next_project.cta.title,
												href: next_project.cta.link,
												external: next_project.cta.external,
												aria_label: next_project.cta.aria_label
											}
										}
										className='btn btn-primary btn-rounded'
									/>
								}
							</div>
						}

						{
							<div className='footer-bottom'>
								{
									validateArray(social_links) &&
									<ul className="social-nav mr-auto">
										{
											social_links.map((item, index) =>
												item.link &&
												<li className="nav-item" key={index}>
													<a
														className={'nav-link'}
														href={item.link}
														title={item.icon_title}
														target={item.external ? '_blank' : ''}
														rel={item.external ? 'noopener noreferrer' : ''}
													>
														<span className={`fa fa-${(item.icon_name).toLowerCase()}`} />
													</a>
												</li>
											)
										}
									</ul>
								}
								<div className='footer-left-content'>
									{
										validateArray(navigation) &&
										<ul className='footer-nav list-unstyled'>
											{
												navigation.map((item, index) =>
													item.title &&
													<li className="nav-item" key={index}>
														<BaseLink
															data={
																{
																	display_title: item.title,
																	href: item.link,
																	external: item.external,
																	aria_label: item.aria_label
																}
															}
															className='nav-link'
															rel={item.external ? 'noopener noreferrer' : ''}
														/>
													</li>
												)
											}
										</ul>
									}
									{
										copyright &&
										<div className='footer-copyright' dangerouslySetInnerHTML={{ __html: copyright }} />
									}
									{
										validateImage(soc_section) &&
										<Image
											className="soc-footer-logo"
											data={soc_section[0]}
											format={false}
											resizeWidth={100}
											width={70}
										/>
									}
								</div>
							</div>
						}
					</div>
				</footer>
			)
		}
		}
	/>

)


Footer.propTypes = {
	hideFooterNextSection: PropTypes.bool
}

export default Footer

export const query = graphql`
{
    contentstackFooter {
      next_project {
        heading
        cta {
            title
            link
            external
        }
      }
      navigation {
       title
       link
       external
      }
      copyright
      soc_section{
        image_alt
        image{
          url
        }
      }
      social_links {
        link
        icon_name
		icon_title
		external
      }
    }
  }
`;

