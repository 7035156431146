/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon from '../images/favicon.ico';

function SEO({ description, keywords, lang, meta, is_parent_directory, pageURL = '', title, hasMarketo = false ,
extension}) {
	const { site, config } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
          }
		}
		config:contentstackSiteConfig {
			config_variables {
			  environment
			  url
			}
		}
      }
    `
	)

	const metaDescription = description || site.siteMetadata.description
	const metaKeywords = keywords || site.siteMetadata.keywords
	const metaTitle = (title && title.toLowerCase().indexOf('raw engineering') > -1 ? title : 'Raw Engineering: ' + title) || 'Raw Engineering: ';
	const logoStaicURL = 'https://www.raweng.com/v3/assets/bltaacb6b0c9b693c2d/bltc1a71705f49d391d/5de663548d1dce6ad6bed8bf/header-logo.svg?format=pjpg&width=220'
	const ldJson = {
		'@context': 'http://schema.org',
		'@type'   : 'Organization',
		name      : 'Raw Engineering',
		url       : 'https://www.raweng.com/',
		logo      : `${logoStaicURL}`,
		sameAs    : [
		  'https://www.facebook.com/rawengineering/',
		  'https://twitter.com/raweng',
		  'https://www.linkedin.com/company/raw-engineering',
		]
	};

	let domain = 'https://www.raweng.com';

	if (config && config.config_variables && config.config_variables.length) {
		for (let i = 0; i < config.config_variables.length; i++) {
			if (config.config_variables[i].environment &&
				(config.config_variables[i].environment.toLowerCase() === process.env.GATSBY_CONTENTSTACK_ENVIRONMENT)
			) {
				domain = config.config_variables[i].url
				break;
			}
		}
	}

	//raweng.png image added in static folder
	const previewImg = domain ? domain + '/raweng.png' : '/raweng.png';

	return (
		<Helmet
			bodyAttributes={{
				"data-pageref":extension.uid,
				"data-contenttype":extension.contenttype,
				"data-locale":extension.locale
			}}
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			titleTemplate={`%s`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: `keywords`,
					content: metaKeywords,
				},
				{
					property: `og:title`,
					content: metaTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:keywords`,
					content: metaKeywords,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: previewImg || ''
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:site`,
					content: `@raweng`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: metaTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `twitter:keywords`,
					content: metaKeywords,
				},
				{
					property: `twitter:image`,
					content: previewImg || ''
				},
			].concat(meta)}
		>
			<link rel="icon" href={favicon} />
			{
				pageURL &&
				!is_parent_directory &&
				<link rel="canonical" href={domain + pageURL} />
			}

			<script
				type="text/javascript"
				src="https://app-sj21.marketo.com/js/forms2/js/forms2.min.js"
			/>
			<script type="application/ld+json">
   				{JSON.stringify(ldJson)}
  			</script>

			{
				hasMarketo &&
				<script type="text/javascript">
					{
						`MktoForms2.loadForm("//app-sj21.marketo.com", "336-BHM-695", 1074, function (form) {

							form.onSuccess(function (values, followUpUrl) {

								form.getFormElem().hide();
								if (document.getElementById('confirmform')) {
									document.getElementById('confirmform').style.display = 'block';
								}

								return false;
							});
						});
						MktoForms2.loadForm("//app-sj21.marketo.com", "336-BHM-695", 1031, function (form) {

							form.onSuccess(function (values, followUpUrl) {

								form.getFormElem().hide();
								if (document.getElementById('confirmform')) {
									document.getElementById('confirmform').style.display = 'block';
								}

								return false;
							});
						});
						`
					}
				</script>
			}

		</Helmet>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: "",
	extension :{
		contenttype:"",
		uid:"",
		locale:""
	}
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	extension: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	title: PropTypes.string.isRequired,
	hasMarketo: PropTypes.bool,
	pageURL: PropTypes.string
}

export default SEO
