import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import './slick-carousel.scss';

class SlickSlider extends Component {

	render() {

		const defaultSettings = {
			dots: true,
			infinite: false,
			speed: 1000,
			slidesToShow: 2,
			slidesToScroll: 1,
			lazyLoad: true,
			adaptiveHeight: true
		};

		const {
			children,
			settings = defaultSettings,
			hasHover = false,
			className = '',
			...other
		} = this.props;

		return (
			<Slider
				{...settings}
				{...other}
				className={(hasHover && 'hasHover ' + className) || className}
			>
				{children}
			</Slider>
		);
	}
}

SlickSlider.propTypes = {
	children: PropTypes.node,
	hasHover: PropTypes.bool,
	className: PropTypes.string
};

export default SlickSlider;
