import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Image = props => {
	const { config } = useStaticQuery(
		graphql`
			query {
				config: contentstackSiteConfig {
					config_variables {
						environment
						url
					}
				}
			}
		`
	)

	const {
		data,
		format = true,
		resizeWidth = null,
		customAlt = "",
		...other
	} = props

	if (!data || !data.image) return null
	const { image = {}, image_alt = "" } = data

	if (image && image.url) {
		if (
			format &&
			resizeWidth &&
			image.url.indexOf("?format=pjpg&width=") <= -1
		) {
			image.url = image.url + "?format=pjpg&width=" + resizeWidth
		} else if (
			format &&
			image.url.indexOf("?format=pjpg") <= -1 &&
			!resizeWidth
		) {
			image.url = image.url + "?format=pjpg"
		} else if (
			resizeWidth &&
			image.url.indexOf("?width=" + resizeWidth) <= -1 &&
			!format
		) {
			image.url = image.url + "?width=" + resizeWidth
		}
	}
	let domainUrl = "https://www.raweng.com"
	let imgUrl
	let cleanTitle =
		(image.title &&
			image.title
				.replace(/.pdf|.svg|.png|.jpg|.jpeg|.gif|.bmp|.apng/gi, "")
				.replace(/@2x|_|-/gi, " ")
				.trim()) ||
		""
	let altText = image_alt || image.description || cleanTitle || ""
	if (config && config.config_variables && config.config_variables.length) {
		for (let i = 0; i < config.config_variables.length; i++) {
			if (
				config.config_variables[i].environment &&
				config.config_variables[i].environment.toLowerCase() ===
					process.env.GATSBY_CONTENTSTACK_ENVIRONMENT
			) {
				domainUrl = config.config_variables[i].url
				imgUrl = image.url.replace("https://images.contentstack.io", domainUrl)
				break
			}
		}
	}
	return <img src={imgUrl} alt={customAlt || altText} {...other} />
}

Image.propTypes = {
	data: PropTypes.object,
	format: PropTypes.bool,
	resizeWidth: PropTypes.number,
	customAlt: PropTypes.string,
}

export default Image