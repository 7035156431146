import React from "react";
import PropTypes from "prop-types";

import { Image } from '../index';
import { validateSingleImage } from '../utilities/functions';

import styles from './index.module.scss';

const HeroBanner = (props) => {
	const {
		image,
		heading = '',
		className = '',
		imageAlt = '',
		headingClass = '',
		h1Type = true,
		hasContainer = true,
		hasOverlayBG = false
	} = props;

	if (!validateSingleImage(image))
		return null;

	return (
		<>
			{
				(hasContainer &&
					<div className={`${(hasOverlayBG ? styles.hasOverlayBG : '')}`}>
						<div className='container container--1300'>
							<div className={(styles.hero ? styles.hero : '') + ' text-center ' + className}>
								<Image
									className={styles.hero_Image + ' img-100'}
									data={{ image: { url: image.url }, image_alt: imageAlt }}
									resizeWidth={1210}
								/>
								{
									heading &&
									(h1Type &&
										<h1 className={styles.hero_Heading + ' ' + headingClass}>{heading}</h1>
										||
										<h2 className={styles.hero_Heading + ' ' + headingClass}>{heading}</h2>)

								}
							</div>
						</div>
					</div>
				)
				||
				<div className={(styles.hero ? styles.hero : '') + ' text-center ' + styles.fullWidthHero + className}>
					<Image
						className={styles.hero_Image + ' img-100'}
						data={{ image: { url: image.url }, image_alt: imageAlt }}
						resizeWidth={1210}
					/>
					<div className={`container container--1300 ${styles.heroContainer}`}>
						{
							heading &&
							(h1Type &&
								<h1 className={styles.hero_Heading + ' ' + headingClass}>{heading}</h1> ||
								<h2 className={styles.hero_Heading + ' h1 ' + headingClass}>{heading}</h2>)
						}
					</div>
				</div>
			}
		</>
	);
};

HeroBanner.propTypes = {
	hasContainer: PropTypes.bool,
	children: PropTypes.node,
	heading: PropTypes.string,
	image: PropTypes.object,
	imageAlt: PropTypes.string,
	headingClass: PropTypes.string,
	hasOverlayBG: PropTypes.bool
};

export default HeroBanner;
