import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';

import { Image } from '../index';
import { validateSingleImage, validateArray } from '../utilities/functions';


import styles from './index.module.scss';
// import 'aos/dist/aos.css';

class TimeLine extends Component {

	componentDidMount() {
		AOS.init({
			duration: 2000,
			disable: 'mobile',
			once: true
		})
	}

	render() {

		const {
			data,
		} = this.props;

		return (
			<div className={`${styles.timeline_Section} animation-wrapper timeline-wrapper `}>
				{
					validateArray(data.journey) &&
					data.journey.map((item, index) =>
						item.year &&
						validateArray(item.details) &&
						item.details.map((nav, idx) =>
							<div
								key={idx}
								className={`${styles.timeline_col} `}
							// data-aos="fade-up"
							>
								<div
									style={{ color: item.timeline_color }}
									className={`${styles.timeline_OvalWrapper} ${(idx === 0 ? styles.isMajor : '')}`}
								>
									<span
										style={{
											backgroundColor: (idx === 0 ? 'white' : item.timeline_color),
											borderColor: item.timeline_color
										}}
										className={` ${styles.oval} ${(idx === 0 ? styles.isMajorOval : '')}`}
									/>
								</div>
								<div data-aos="fade-up">
									<h3
										style={{ color: item.timeline_color }}
										className={`${styles.timeline_yearText} ${(idx === 0 ? styles.isMajor : '')}`}
									>
										<span>{item.year}</span>
										<span
											className={styles.dottedLines}
											style={{ backgroundImage: 'linear-gradient(to right, ' + (item.timeline_color ? item.timeline_color : 'black') + ' 25%, rgba(255, 255, 255, 0) 0%)' }}
										/>
									</h3>
									{
										nav.title_text &&
										<h6>{nav.title_text}</h6>
									}
									{
										validateSingleImage(nav.title_image) &&
										<div className={styles.title_Image} >
											<Image
												width={250}
												resizeWidth={250}
												format={false}
												data={{ image: nav.title_image, image_alt:nav.image_alt }}
											/>
										</div>
									}
									{
										nav.timeline_description &&
										<p className={styles.timeline_Desc + ' p__body1'} dangerouslySetInnerHTML={{ __html: nav.timeline_description }} />
									}
								</div>
							</div>
						)
					)
				}
			</div>
		);
	}
}

TimeLine.propTypes = {
	children: PropTypes.node
};

export default TimeLine;
