import React from "react";
import PropTypes from "prop-types";
import { Image } from '../index';
import { validateSingleImage, validateArray } from '../utilities/functions';
import styles from './index.module.scss';

const TechnologiesStack = (props) => {

	const {
		data,
		className = '',
		hasOpacity = false,
		resizeWidth = 131
	} = props;

	if (!validateArray(data))
		return null;

	return (
		<>
			{
				validateArray(data) &&
				<div className={`${styles.TechIcons__row} ${className} `}>
					{
						data.map((item, index) =>
							validateSingleImage(item.image) &&
							<div className={`${styles.TechIcons__Image} ${(hasOpacity ? styles.hasHover : '')}`} key={index}>
								<Image
									data={item}
									resizeWidth={resizeWidth}
								/>
							</div>
						)
					}
				</div>
			}
		</>
	);
};

TechnologiesStack.propTypes = {
	data: PropTypes.array,
	className: PropTypes.string,
	hasOpacity: PropTypes.bool,
	resizeWidth: PropTypes.number
};

export default TechnologiesStack;
