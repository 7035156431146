import React from "react";
import PropTypes from "prop-types";

import { Image, SlickSlider, userDefault } from '../../components';
import { validateSingleImage, validateArray } from '../utilities/functions';
import styles from './index.module.scss';

const TestimonialSlider = (props) => {

	const testimonialSettings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: true,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	const {
		data,
		className = '',
		settings = testimonialSettings,
	} = props;

	if (!validateArray(data))
		return null;

	return (

		data &&
		<div className={`${className} bg-darkgrey text-center box-10080`}>
			<div className='container container--980'>
				<SlickSlider settings={settings} className={` ${styles.testimonial_Slider} ${styles.theme__dark}`}>
					{
						data.map((card, index) =>
							<React.Fragment key={index} >
								{
									card.comment &&
									<blockquote
										className={styles.author_Quote}
										dangerouslySetInnerHTML={{ __html: card.comment }}
									/>
								}
								<div className={'text-center ' + styles.author_Profile_Pic}>
									{
										validateSingleImage(card.thumbnail) ?
											<Image
												data={{ image: card.thumbnail, image_alt: card.image_alt }}
												resizeWidth={70}
											/>
											:
											<img src={userDefault} alt={card.image_alt || card.author} />
									}
								</div>
								{
									card.author &&
									<h6 className={`${styles.testimonial_Author}`}>{card.author}</h6>
								}
								{
									card.role &&
									<p className={`${styles.author_Role}`}>{card.role}</p>
								}
							</React.Fragment>
						)
					}
				</SlickSlider>
			</div>
		</div>
	);
};

TestimonialSlider.propTypes = {
	children: PropTypes.node,
	data: PropTypes.array,
	className: PropTypes.string,
	settings: PropTypes.object
};

export default TestimonialSlider;
