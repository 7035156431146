import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { StaticQuery, graphql } from "gatsby";
import AnimateHeight from 'react-animate-height';

import { Image, BaseLink } from '../../components';
import { validateArray, validateSingleLink } from '../utilities/functions';

import './header.scss';

const Header = ({ pageURL }) => {

	const [isOpen, setIsOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [activeIndex, setActiveIndex] = useState(-1);

	const node = useRef();

	const toggle = (e) => {
		setActiveIndex(-1);
		if (node.current.contains(e.target)) {
			setIsOpen(!isOpen);
			return;
		}
		//outside click
		setIsOpen(false);
	}

	const handleScroll = () => {
		const currentScrollPos = typeof window !== "undefined" && window.pageYOffset;
		const visible = currentScrollPos > 70;
		setIsVisible(visible);
	};

	const menuclicked = (index) => {
		activeIndex === index ? setActiveIndex(-1) : setActiveIndex(index);
	}

	useEffect(() => {
		// add when mounted
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", handleScroll);
		}
		// return function to be called when unmounted
		return () => {
			if (typeof window !== "undefined") {
				window.removeEventListener("scroll", handleScroll);
			}
		};
	}, []);

	return (
		<StaticQuery
			query={query}
			render={data => {

				if (!data && !data.header) return null;

				const { header_logo, contact_cta, header_nav } = data.header;

				const contactCTA = (
					validateSingleLink(contact_cta) &&
					<BaseLink
						className='btn btn-primary btn-rounded text-uppercase'
						data={{ display_title: contact_cta.title, href: contact_cta.link, external: contact_cta.external }}
					/>
				);

				return (
					<header className={'main-header header-wrapper pinstripes-bg ' + (isVisible ? '--sticky' : '')} id='main-header'>
						<div className='container header-container'>
							{
								header_logo &&
								<a href={header_logo.link} className='logo-link'>
									{
										header_logo.image &&
										<Image
											data={{ image: header_logo.image, image_alt: header_logo.title }}
											resizeWidth={220}
										/>
									}
								</a>
							}

							<div
								className={`navbar-toggle hamburger-icon ${(isOpen ? '--active' : '')}`}
								onClick={toggle}
								ref={node}>
								<span className="sr-only">Toggle navigation</span>
								<span className='toggle-line'></span>
							</div>

							<AnimateHeight
								duration={500}
								height={(isOpen ? 'auto' : 0)}
								className={'main-navbar ' + (isOpen ? 'active' : 0)}
							>
								<nav className={'header-nav '
									// + (isOpen ? 'nav--open' : 'nav--closed')
								}>
									<ul className="navbar-nav mr-auto">
										{
											validateArray(header_nav) &&
											header_nav.map((item, index) =>
												item.title &&
												<li className={`nav-item ${((validateArray(item.sub_navigation)) ? ' hasDropdown' : '')} `} key={index}>
													{
														item.link ?
															<BaseLink
																data={{ display_title: item.title, href: item.link, external: item.external }}
																className={`nav-link ${(pageURL && (pageURL.indexOf(item.link) > -1) ? '' : '')}`}
															/>
															:
															((validateArray(item.sub_navigation) && item.title)
																&&
																<span className={`nav-link`}>{item.title}</span>)
													}
													{
														(validateArray(item.sub_navigation)) &&
														<span
															className={'next-arrow ' + (activeIndex === index ? 'active' : '')}
															onClick={() => menuclicked(index)}
														></span>
													}
													{
														validateArray(item.sub_navigation) &&
														<AnimateHeight
															duration={500}
															height={((activeIndex === index) ? 'auto' : 0)}
														><ul className='sub-navigation' >
																{
																	item.sub_navigation.map((subnav, idx) =>
																		<li className="sub-nav-item" key={idx}>
																			<BaseLink
																				data={{ title: subnav.title, href: subnav.link, external: subnav.external }}
																				className={'sub-nav-link ' + (pageURL && (pageURL.indexOf(item.link) > -1) ? 'active' : '')}
																			/>
																		</li>
																	)
																}
															</ul>
														</AnimateHeight>
													}
												</li>
											)
										}
									</ul>
									{
										validateSingleLink(contact_cta) &&
										<div className='desktop-nav-item'>
											{contactCTA}
										</div>
									}
								</nav>
							</AnimateHeight>
						</div>
					</header>
				)
			}
			}
		/>

	)
};


Header.propTypes = {
	pageURL: PropTypes.string
}

export default Header

export const query = graphql`
{
	header:contentstackSiteHeader(uid: {eq: "blt8e96cd5d1934e6cb"}) {
	  header_logo {
      title
      link
      image {
        url
      }
	  }
	  header_nav {
      title
      link
      external
      sub_navigation{
        title
        link
        external
      }
	  }
	  contact_cta {
      title
      link
      external
	  }
	}
}
`;
