import React from "react"
import PropTypes from "prop-types"

import { Image, BaseLink } from "../index"
import { validateArray, validateSingleImage } from "../utilities/functions"

import styles from "./index.module.scss"

const CaseStudies = props => {
	const {
		showThreeCards = true,
		data,
		heading,
		headingClass = "",
		className = "",
		description,
	} = props

	if (!validateArray(data)) return null

	let cardsdata = data

	if (showThreeCards) {
		cardsdata = data.slice(0, 3)
	}

	const casestudyCol = (card, index) => {
		return (
			<div
				data-aos="fade-up"
				className={"col-12 col-992-4 casestudy-card " + styles.casestudy_Col}
				key={index}
			>
				<div
					className={` ${styles.work_Card} ${
						card.thumbnail_info.short_description ? styles.hasDesc : ""
					}`}
				>
					{card.url && (
						<BaseLink
							data={{ display_title: "View case study", href: card.url }}
						>
							<div className={styles.card_Img}>
								{validateSingleImage(card.thumbnail_info.image) && (
									<Image
										className="img-100"
										format
										resizeWidth={400}
										data={{
											image: card.thumbnail_info.image,
											image_alt: card.thumbnail_info.image_alt,
										}}
									/>
								)}
							</div>
							<div className={`${styles.card_Content} ${styles.card_Overlay}`}>
								{card.thumbnail_info.tag && (
									<span className={styles.card_Tag}>
										{card.thumbnail_info.tag}
									</span>
								)}
								{card.thumbnail_info.heading && (
									<h3 className={styles.card_Title}>
										{card.thumbnail_info.heading}
									</h3>
								)}
								{card.thumbnail_info.short_description && (
									<p>{card.thumbnail_info.short_description}</p>
								)}
								{card.url && (
									<span className="link">View case study &rarr;</span>
								)}
							</div>
						</BaseLink>
					)}
				</div>
			</div>
		)
	}

	return (
		<div className={"latest-work-section " + className}>
			<div className="container">
				{heading && (
					<h2 className={headingClass}>
						<span>{heading}</span>
					</h2>
				)}
				{description && (
					<div
						className="casestudy-desc"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
				{((showThreeCards || cardsdata.length <= 3) && (
					<div className={`row ${styles.casestudy_Row}`}>
						{cardsdata.map(
							(card, index) => card.thumbnail_info && casestudyCol(card, index)
						)}
					</div>
				)) || (
					<>
						{cardsdata.map(
							(card, index) =>
								card.thumbnail_info &&
								index % 2 === 0 && (
									<div
										key={index}
										className={`row ${styles.casestudy_Row} ${styles.casestudy_twoColRow}`}
									>
										{casestudyCol(card, index)}
										{cardsdata[index + 1] &&
											casestudyCol(cardsdata[index + 1], index + 1)}
									</div>
								)
						)}
					</>
				)}
			</div>
		</div>
	)
}

CaseStudies.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	children: PropTypes.node,
	heading: PropTypes.string,
	headingClass: PropTypes.string,
	description: PropTypes.string,
	showThreeCards: PropTypes.bool,
}

export default CaseStudies
