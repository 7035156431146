import React from "react";
import PropTypes from "prop-types";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./layout.scss";
import '../../scss/aos-animation/aos.scss';
const Layout = ({ children, className = '', pageURL, hideFooterNextSection = false }) => {

	return (
		<div className={className + ' main-wrapper'}>
			<Header pageURL={pageURL} />
			<div className={'main-content'}>
				<div className='main-innercontent'>{children}</div>
			</div>
			<Footer hideFooterNextSection={hideFooterNextSection} />
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	pageURL: PropTypes.string,
	hideFooterNextSection: PropTypes.bool
}

export default Layout
