import React from "react";
import PropTypes from "prop-types";

const BaseLink = (props) => {
    const {
        data,
        children,
        ...other
    } = props;

    if (!data || !data.href)
        return null;

    const {
		title,
		display_title=title,
        href,
        external,
        aria_label,
        // internal_image = {}
    } = data;

    return (
        (external || href.includes('#')) ? (
            <a
                href={href}
                target={external ? '_blank':''}
                rel='noopener noreferrer'
                // title={display_title}
                aria-label={aria_label}
                {...other}
            >
                {children || display_title}
            </a>
        ) : (
                <a
                    href={href}
                    // title={display_title}
                    aria-label={aria_label}
                    {...other}
                >
                    {children || display_title}
                </a>
            )
    );
};

BaseLink.propTypes = {
    data: PropTypes.object,
    children: PropTypes.node
};

export default BaseLink;
