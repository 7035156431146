// Array validation - pass array in and check for length to be more than 0.
export const validateArray = array => Array.isArray(array) && !!array?.length;

// Use: validateSingleLink(image)
export const validateSingleLink = link => link?.link;

// Use: validateSingleImage(image)
export const validateSingleImage = image => image?.url;

// Link validation - pass link in to array validator and check for href existence.
export const validateLink = link => validateArray(link) && link[0].href;

// Image validation - pass image in to array validator and check for url existence.
export const validateImage = image => validateArray(image) && image[0].image && image[0].image.url;

// if image is coming from global field
export const validateImageCT = image => image && image.image && image.image.url;

//use : validationAssests(assest)
export const validateAssests = assest => assest && assest.url;


//Added due to issue from CMS returning empty tags
export const validateRTE = desc => desc && desc !== '<p><br></p>';

//get Query params
export const getUrlParameter = (name) => {
	if (typeof window !== 'undefined') {
		if (window && window.location) {
			name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
			var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
			var results = regex.exec(window.location.search);
			return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
		}
	}
};

// unescapeHTML html
export const unescapeHTML = (html) => {
	var escapeEl = document.createElement('textarea');
	escapeEl.innerHTML = html;
	return escapeEl.textContent;
}

export const jobBoardName = process.env.GATSBY_GH_JOB_BOARD_NAME;
