import React from "react";
import PropTypes from "prop-types";

import { Image, SlickSlider } from '../index';
import { validateSingleImage, validateArray } from '../utilities/functions';

import styles from './index.module.scss';

const HeroSlider = (props) => {

	const heroSettings = {
		dots: false,
		arrows: false,
		infinite: false,
		autoplay: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: true
	};

	const {
		data,
		className = '',
		settings = heroSettings,
		headingClass = ''
	} = props;

	if (!data)
		return null;

	return (

		data &&
		<div className={`${styles.heroSlider} ${className}`}>
			<div className={styles.container + ' pinstripes-bg'}>
				<div className={styles.hero_container}>
					{
						validateSingleImage(data.hero_image) &&
						<div className={` ${styles.hero_Image} `} data-aos="fade-left">
							<Image className='img-100' resizeWidth={540} data={{ image: data.hero_image, image_alt: data.image_alt }} />
						</div>
					}
					{
						validateArray(data.content) &&
						<div className={` ${styles.banner_Content} `} data-aos="fade-right">
							<SlickSlider settings={settings}>
								{
									data.content.map((item, index) =>
										<React.Fragment key={index}>
											{
												item.heading &&
												<h1 className={` ${styles.hero_Title} ${headingClass}`}>{item.heading}</h1>
											}
											{
												item.description &&
												<div className={`${styles.hero_Description}`} dangerouslySetInnerHTML={{ __html: item.description }} />
											}
											{
												item.cta &&
												item.cta.link &&
												<a href={item.cta.link} className='link' target={item.external ? '_blank' : '_self'}>
													{item.cta.title} &rarr;
											</a>
											}
										</React.Fragment>
									)
								}
							</SlickSlider>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

HeroSlider.propTypes = {
	children: PropTypes.node,
	data: PropTypes.object,
	headingClass: PropTypes.string,
	className: PropTypes.string,
	settings: PropTypes.object
};

export default HeroSlider;
